<template>
  <v-dialog
    v-model="myValue"
    persistent
    width="fit-content"
  >
    <v-card 
      class="dialog-create-contract-card"
      min-width="360"
    >
      <v-card-title class="py-5">
        <span class="card-title-text px-3 pt-1">Create New Request</span>
        <v-spacer></v-spacer>
        <v-btn text small icon class="mr-n1 my-1" @click="closeMe">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="card-content px-5 pt-1 pb-8">
        <v-btn text block height="48" class="contract-type-btn pl-3 my-0" color="grey darken-1" @click="clickClientProjectContract">
          <v-icon class="mr-3" size="24">mdi-file-document-outline</v-icon>
          <span class="mr-3">Client Project Contract</span>
        </v-btn>
        <v-list class="py-0">
          <v-list-group
            v-for="contract in contracts"
            :key="contract.title"
            v-model="contract.active"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="mr-4 grey--text text--darken-1" style="margin-left:-3px" size="24">mdi-file-account-outline</v-icon>
                  <span class="mr-3 text-body-2 grey--text text--darken-1">Vendor Contract</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="contractItems in contract.items"
              :key="contractItems.title"
              dense
            >
              <v-list-item-content class="ml-n6">
                <v-list-item-title>
                  <v-btn text block class="contract-type-btn pl-1 my-0" color="grey darken-1" @click="contractItems.function">
                    <v-icon class="mr-2 text-body-1 grey--text text--darken-1" style="margin-left:-3px" size="24">mdi-minus</v-icon>
                    <span class="mr-3">{{ contractItems.title }}</span>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-btn text block height="48" class="contract-type-btn pl-3 my-0" color="grey darken-1" @click="clickOtherLegalRequests">
          <v-icon class="mr-3" size="24">mdi-file-table-outline</v-icon>
          <span class="mr-3">Other Legal Requests</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      myValue: false,
      contracts: [
        {
          icon: 'mdi-file-account-outline',
          title: 'Vendor Contract',
          active: false,
          items: [
            { title: 'NIQ', function: this.clickVendorContract },
            // project-dev-to-live -- test only - available to pro after confirmed
            // { title: 'GfK', function: this.clickGfkVendorContract },
          ],
        },
      ],
    }
  },
  methods:{
    closeMe () {
      this.myValue = false
    },
    clickClientProjectContract () {
      this.$emit('click-client-project-contract')
    },
    clickVendorContract () {
      this.$emit('click-vendor-contract')
    },
    clickGfkVendorContract () {
      this.$emit('click-gfk-vendor-contract')
    },
    clickOtherLegalRequests () {
      this.$emit('click-other-legal-requests')
    }
  },
  mounted () {
    // preset myValue
    if (this.myValue !== this.value) {
      this.myValue = this.value
    }
  },
  watch: {
    value: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.myValue = this.value
        }
      }
    },
    myValue: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.$emit('change', nv)
          this.contracts.forEach(contract => contract.active = false)
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .dialog-create-contract-card
    .card-title-text
      font-size: 18px

    .card-content
      text-align: left

      .contract-type-btn
        .v-btn__content
          justify-content: flex-start !important

        &:hover:before
          background-color: rgba(0, 0, 0, .6)
</style>
