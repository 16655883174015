<template>
  <v-layout wrap>
    <v-flex
      xs12
    >
      <material-app-card
        class="contract-list"
        color="btn-color-default"
        icon="mdi-text-box"
        title="My Requests"
      >
        <template v-slot:extra>
          <v-tooltip left :disabled="$vuetify.breakpoint.smAndUp || isMobile">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="!isMobile"
                v-on="on"
                text
                small
                class="before-workflow-list-btn-new primary extra-button-text"
                :icon="!$vuetify.breakpoint.smAndUp"
                @click="showCreateContractSelect"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
                <span 
                  style="font-size:14px;padding-left:8px;font-weight:bold"
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  Create New Request
                </span>
              </v-btn>
            </template>
            <span>Create New Request</span>
          </v-tooltip>
        </template>
        <workflow-list
          class="requestor-workflows"
          table-id="my-contract"
          type="contract"
          @click-item="reviewContract"
          @auth-fail="logoff"></workflow-list>
      </material-app-card>
    </v-flex>

    <dialog-create-contract
      v-model="isSelectingCreateContractType"
      @click-client-project-contract="addClientProjectContract"
      @click-vendor-contract="addVendorContract"
      @click-gfk-vendor-contract="addGfkVendorContract"
      @click-other-legal-requests="addOtherLegalRequest"
    >
    </dialog-create-contract>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import workflowList from "@/components/app/workflow-list-v3";
import dialogCreateContract from "@/components/app/dialog/create-contract";

export default {
  components: {
    workflowList,
    dialogCreateContract
  },
  data () { return {
    isSelectingCreateContractType: false
  }},
  computed: {
    ...mapGetters('app', [
      'isMobile'
    ])
  },
  methods: {
    ...mapActions('app', ['setMainTitle']),
    
    showCreateContractSelect () {
      this.isSelectingCreateContractType = true
    },
    addClientProjectContract() {
      try {
        this.$router.push({path: '/legal/request/details/client-project/new'})
      } catch (err) {
        console.log('Error [add client project contract]: ')
        // console.log(err)
      }
    },
    addVendorContract() {
      try {
        this.$router.push({path: '/legal/request/details/vendor/new'})
      } catch (err) {
        console.log('Error [add legal request]: ')
        // console.log(err)
      }
    },
    addGfkVendorContract() {
      try {
        this.$router.push({path: '/legal/request/details/gfk-vendor/new'})
      } catch (err) {
        console.log('Error [add gfk legal request]: ')
        // console.log(err)
      }
    },
    addOtherLegalRequest() {
      try {
        this.$router.push({path: '/legal/request/details/other/new'})
      } catch (err) {
        console.log('Error [add legal request]: ')
        // console.log(err)
      }
    },
    reviewContract(item) {
      try {
        if (!item || !item.item || !item.item.token || !item.item.requestType) {
          throw 'Missing Params'
        }

        switch (item.item.requestType) {
          case 'Client Project Contract':
            this.$router.push({path: `/legal/request/details/client-project/${item.item.token}`})
            break;

          case 'Vendor Contract':
            this.$router.push({path: `/legal/request/details/vendor/${item.item.token}`})
            break;

          case 'Other Legal Requests':
            this.$router.push({path: `/legal/request/details/other/${item.item.token}`})
            break;

          case 'GfK Vendor Contract':
            this.$router.push({path: `/legal/request/details/gfk-vendor/${item.item.token}`})
            break;
        
          case "WBS Request":
            this.$router.push({path: `/ci/wbs/details/${item.item.token}`})
            break;
            
          default:
            break;
        }
      } catch (err) {
        console.log('Error [review contract]: ')
        // console.log(err)
      }
    },
    logoff() {
      this.$router.push({path: '/login'})
    }
  },
  created () {
    this.setMainTitle('My Requests')
  }
}
</script>

<style lang="sass">
  .contract-list
    .extra-button-text
      padding: 8px 14px 8px 8px !important

    .requestor-workflows
      margin-top: -32px
</style>
